/** @jsx jsx */
import { css } from "@emotion/react";

export const headerContent = css`
  padding: 56px 0px;
  font-family: "kallisto", sans-serif;
  background: #F9F9F9;
  margin-top: 114px;
  h1 {
    font-variation-settings: "wght" 130, "wght" 700;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    color: #222222;
    margin-bottom: 8px;
  }
  h4 {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: #222222;
    margin-bottom: 0px;
  }
  img {
    width: 100%;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 24px 0px;
    margin-top: 108px;
    img {
      margin: 24px 0px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding: 24px 0px;
    margin-top: 108px;
    img {
      margin: 24px 0px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding: 24px 0px;
    margin-top: 108px;
    img {
      margin: 24px 0px;
    }
  }
  /*@media (min-width: 991px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
  } */
`
export const setaHover = css`

img {
 opacity: 0.5;
 transition: transform 250ms;
}

img:hover {
 opacity: 1;
 transform: translateY(-10px);
}

@media (min-width: 320px) and (max-width: 767px) {
   img{
    opacity: 1;
   } 
  }
`

export const headerMoreImages = css`
  padding: 56px 0px;
  font-family: "kallisto", sans-serif;
  background: #F9F9F9;
  margin-top: 114px;
  img {
    width: 100%;
    margin-bottom: 8px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    
  }

  @media (min-width: 481px) and (max-width: 767px) {
    
  }

  @media (min-width: 768px) and (max-width: 990px) {
   
  }
  /*@media (min-width: 991px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
  } */
`

export const turmasContent = css`
  background: #F9F9F9;
  font-family: "kallisto", sans-serif;
  padding-bottom: 112px;
  h2 {
    color: #222222;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 24px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding-top: 56px;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding-top: 56px;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding-top: 56px;
  }
`

export const boxAgendeSuaVisita = css`
  border: 1px solid #DDD;
  background: rgba(255, 255, 255, 0.00);
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12);
  padding: 24px;
  h4 {
    font-family: "kallisto", sans-serif;
    font-variation-settings: "wght" 130, "wght" 700;
    color: #222;
    font-family: Kallisto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 22px;
  }
  form {
    font-family: 'Montserrat', sans-serif;
    input {
      border: 1px solid #D4D4D4;
      width: 100%;
      height: 52px;
      background: #FAFAFA;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: #7C7C7C !important;
      padding-left: 24px;
      padding-right: 24px;
    }
    select {
      border: 1px solid #D4D4D4;
      width: 100%;
      height: 52px;
      background: #FAFAFA;
      padding-left: 24px;
      padding-right: 24px;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: #7C7C7C !important;
    }
  }
`
export const locacalizaoContato = css`
  background: #F9F9F9;
  font-family: "kallisto", sans-serif;
  padding-bottom: 112px;
  h2 {
    color: #222222;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 8px;
  }
  p {
    color: #222;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; 
    margin-bottom: 32px;
  }
`

export const faleConosco = css`
  font-family: "kallisto", sans-serif;
  margin-top: 120px;
  h2 {
    color: #222222;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 32px;
    text-align: center;
  }
  form {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    max-width: 730px;
    margin: auto;
    input {
      border: 1px solid #D4D4D4;
      width: 100%;
      height: 52px;
      background: #FAFAFA;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: #7C7C7C !important;
      padding-left: 24px;
      padding-right: 24px;
    }
    textarea {
      width: 100%;
      background: #FAFAFA;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: #7C7C7C !important;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 10px;
      margin-bottom: 13px;
      border: 1px solid #D4D4D4;
    }
    select {
      border: 1px solid #D4D4D4;
      width: 100%;
      height: 52px;
      background: #FAFAFA;
      padding-left: 24px;
      padding-right: 24px;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: #7C7C7C !important;
    }
  }
`

export const noPr  = css`
  padding-right: 0px !important;
  @media (min-width: 320px) and (max-width: 767px) {
    padding-right: 15px !important;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding-right: 15px !important;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding-right: 15px !important;
  }
`

export const mostrarFotos = css`
  padding-right: 8px;
  a {
    border-radius: 8px;
    border: 1px solid #222;
    background: #FFF;
    color: #111;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    padding: 8px;
    align-items: center;
    display: flex;
    width: 100%;
    max-width: 224px;
    margin-left: auto;
    margin-top: -60px;
    img {
      width: auto;
      margin-right: 8px;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    a {
      margin-top: auto;
    }
    img {
      margin: 0px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    a {
      margin-top: auto;
    }
    img {
      margin: 0px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    a {
      margin-top: auto;
    }
    img {
      margin: 0px;
    }
  }
`

export const btnEnviar = css`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
  font-variation-settings: "wght" 700;
  background: #75C721;
  width: 100%;
  height: 52px;
  border: none;
  :hover {
    color: #ffffff;
    border: 1px solid #996161;
    background: #000000;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    margin-top: auto;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    margin-top: auto;
  }
  @media (min-width: 768px) and (max-width: 990px) {
    margin-top: auto;
  }
`


export const DDesktop = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: inline;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: inline;
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
    img {
      width: 100%;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
    img {
      width: 100%;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
    img {
      width: 100%;
    }
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`

export const DMobile2 = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: block;
    img {
      width: 100%;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: block;
    img {
      width: 100%;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: block;
    img {
      width: 100%;
    }
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`
